<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-card>
                    <template v-for="(item, key) in laboratorios">
                        <b-button :variant="laboratorioSelected == item ? 'primary' : ''" :key="key" class="mr-1" @click="changeLab(item)">{{item}}</b-button>
                    </template>
                    <b-button :variant="laboratorioSelected == 'nocat' ? 'primary' : ''" class="mr-1" @click="changeLab('nocat')">Sin Clasificar</b-button>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="3">
                <b-card>                    
                    <template v-for="(l, k) in elementos">
                        <b-button v-if="laboratorioSelected == l.lab || (laboratorioSelected == 'nocat' && !l.lab)" variant="flat-primary" :class="active==k? 'active':'' " class="w-full mb-1"  :key="k" @click="cambiarMuestra(l,k)">{{l.name}}</b-button>
                    </template>
                    <b-button variant="primary" class="w-full" @click="createNew()" id="newOptionLab">Nuevo</b-button>
                </b-card>
            </b-col>
            <b-col>
                <b-card>
                    <b-tabs>
                        <b-tab active>
                            <template #title>
                                <feather-icon
                                    icon="InfoIcon"
                                    size="16"
                                    class="mr-0 mr-sm-50"
                                    />
                                <span class="d-none d-sm-inline">Información</span>
                            </template>
                            <information
                                :muestreo="muestreo"
                                :keydb="active"                            
                                class="mt-2 pt-75" 
                                @reset="createNew"                           
                            />
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {BCard, BCol, BRow, BButton, BTab, BTabs} from 'bootstrap-vue'
import information from './Information.vue'
import firebase from 'firebase/app'
import 'firebase/database'
export default {
    components:{
        BCard, BRow, BCol, BButton, BTab, BTabs, information
    },
    data(){
        return{
            elementos: null,
            laboratorios: null,
            laboratorioSelected: null,
            elementosLaboratorio: [],
            selected: null,            
            muestreoBase:{                
                name: "",
                elements: [],
                elementsGenerals: [],
                groups: [],                
                generalAverage: false,
                timesAverage: false,
                times: [],
                lab: null            
            },
            muestreo:{                
                name: "",
                elements: [],
                elementsGenerals:[],
                groups: [],                
                generalAverage: false,
                timesAverage: false,
                times: [],
                lab: null            
            },
            active: null
        } 
    },    
    mounted(){
        this.muestreo = this.muestreoBase
        firebase.database().ref('/muestreo').on('value', (snap)=>{
            this.elementos = snap.val()        
        })
        firebase.database().ref('laboratorios').once('value', (snap)=>{            
            this.laboratorios = snap.val()
            this.laboratorioSelected = snap.val()[0]
        })
    },
    methods:{
        cambiarMuestra(l,k){       
            this.muestreo = l
            this.active = k
        },
        createNew(){     
            console.log('reset')
            this.cambiarMuestra(this.muestreoBase, null)
        },
        changeLab(item){
            this.laboratorioSelected = item                        
        }
    },
    computed:{
        showByLab: ()=>{
            laboratorioSelected == l.lab
        }
    }
}
</script>