<template>
  <div>
    <b-form-group
        label="Laboratorio"
        label-for="lab"
    >
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"      
          
      placeholder="Seleccionar Laboratorio"
      label="Laboratorio"
      id="lab"
      :options="laboratorios"
      v-model="muestreo.lab"
    />
    </b-form-group>
    <b-form-group
        label="Nombre"
        label-for="nameMuestra"
    >
    <b-form-input
        id="nameMuestra"
        placeholder="Nombre"
        v-model="muestreo.name"
    />
    </b-form-group>
    <b-form-group
        label="Grupos"
        label-for="groups"
    >
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      multiple
      taggable
      push-tags
      placeholder="Agregar Grupos"
      label="Grupos"
      id="groups"
      v-model="muestreo.groups"      
    />
    </b-form-group>
    <b-form-group        
        label="Elementos Generales"
        label-for="generalElements"
      >
        <v-select      
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        multiple
        taggable
        push-tags
        placeholder="Agregar elementos generales"        
        id="generalElements"        
        v-model="muestreo.elementosGenerales"        
      />      
    </b-form-group>
    <b-tabs>          
    <template v-for="(element, key) in muestreo.groups">
      <b-tab :key="key">
        <template #title>        
          <span>{{element}}</span>
      </template>
        <b-form-group        
        :label="'Elementos para '+element"
        label-for="elements"
      >
        <v-select      
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          taggable
          push-tags
          :placeholder="'Agregar Elementos para '+ element"        
          :id="'elements'+key"        
          v-model="elementosGroup[element]"          
        />      
        </b-form-group>
      </b-tab>      
    </template>    
    </b-tabs>
    <div class="w-full bg-primary block mb-1" style="height:1px;background:linear-gradient(30deg, #fe6f17, rgba(254, 111, 23, 0.5)) !important" />
    <!-- <b-button @click="replicate" v-if="muestreo.groups.length > 1" class="mb-1">Replicar</b-button> -->
    
    <b-form-group
        label="Horas de Muestreo"
        label-for="hours"
    >
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      multiple
      taggable
      push-tags
      placeholder="Agregar Tiempo"
      label="Horas de Muestreo"
      id="hours"
      :options="times"
      v-model="muestreo.times"
    />
    </b-form-group>
    <b-button variant="primary" @click="createMuestra">{{keydb ? 'Guardar Cambios' : 'Crear'}}</b-button>    
    <b-button variant="info" @click="duplicateMuestra" v-if="keydb" class="ml-1">Duplicar</b-button>
    <b-button variant="danger" @click="deleteMuestra" v-if="keydb" class="ml-1">Borrar</b-button>            
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {BFormInput, BFormGroup, BButton, BTab,BTabs} from 'bootstrap-vue'
import firebase from 'firebase/app'
import 'firebase/database'
export default {
  components: {
    vSelect,BFormInput,BFormGroup,BButton,BTab,BTabs
  },
  data(){
      return{       
            times: [
                "00:00","01:00","02:00","03:00","04:00","05:00",
                "06:00","07:00","08:00","09:00","10:00","11:00",
                "12:00","13:00","14:00","15:00","16:00","17:00",
                "18:00","19:00","20:00","21:00","22:00","23:00",
            ],
            laboratorios: [],
            elementosGroup: {},
            elementsOptions: []            
      }      
  },  
  mounted(){
    firebase.database().ref('laboratorios').once('value').then((snap)=>{
      this.laboratorios = snap.val()
    })    
  },  
  methods:{
      notification(msj='Se ha creado la opcion.', variant='success', title='Correcto!'){
        this.$bvToast.toast(msj, {
          title: title,
          variant: variant,
          solid: true,
        })
      },      
      createMuestra(){        
          if(!this.keydb){
              this.muestreo.elements = this.elementosGroup
              firebase.database().ref('muestreo').push(this.muestreo)  
              this.notification()              
              this.$emit('reset')
          }else{
              this.muestreo.elements = this.elementosGroup
              firebase.database().ref('muestreo/'+this.keydb).set(this.muestreo)              
              this.notification('Los cambios se han realizado.','primary')
              this.$emit('reset')
          }                    
      },
      duplicateMuestra(){
        const toDuplicate = this.muestreo
        toDuplicate.name = this.muestreo.name + ' - Copia'
        firebase.database().ref('muestreo').push(toDuplicate)
        this.$emit('reset')
      },
      deleteMuestra(){        
        const vm = this
        const itemToDelete = firebase.database().ref('muestreo/'+this.keydb)
        itemToDelete.remove().then(function() {          
          vm.notification('Se ha eliminado correctamente.','warning')                    
        })
        .catch(() => {          
          vm.notification('No ha podido ser eliminado.','danger')          
        })
        this.$emit('reset')
      }
  },
  watch:{
    keydb: function (){      
      if(this.muestreo.elements) this.elementosGroup = this.muestreo.elements
      else  this.elementosGroup = {}
    }
  },
  props:{
      muestreo: Object,
      keydb: String,      
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>